import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import React from "react";
import ReactDOM from "react-dom";
import WrappedApp from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/sass/idn-core.min.scss";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import {
  DatepickerProvider, UserProfileProvider, ErrorLoadingProvider, AudienceProvider,
  GetStatusAppProvider, MessageProvider, GetRightProvider
} from "./services/context/index"
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { loadableReady } from '@loadable/component';
import DecoratorPattern from "./services/lib";
import store from './services/redux/store';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();
DecoratorPattern();


//Initialize a PublicClientApplication instance which is provided to the MsalProvider component
const msalInstance = new PublicClientApplication(msalConfig);
loadableReady(() => {
  ReactDOM.render(
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <React.StrictMode>
          <MsalProvider instance={msalInstance}>
            <I18nextProvider i18n={i18n}>
              <BrowserRouter>
                <ReactNotifications />
                <DatepickerProvider>
                  <UserProfileProvider>
                    <ErrorLoadingProvider>
                      <GetStatusAppProvider>
                        <AudienceProvider>
                          <MessageProvider>
                            <GetRightProvider>
                              <WrappedApp />
                            </GetRightProvider>
                          </MessageProvider>
                        </AudienceProvider>
                      </GetStatusAppProvider>
                    </ErrorLoadingProvider>
                  </UserProfileProvider>
                </DatepickerProvider>
              </BrowserRouter>
            </I18nextProvider>
          </MsalProvider>
        </React.StrictMode>
      </Provider>
    </QueryClientProvider>
    ,
    document.getElementById("root")
  );
})


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
//subscribeForPushNotifications();
