import { useTranslation } from "react-i18next";
import Application from "./Application";
import { useApplication } from "../../services/hooks";
import PageContent from "../content/PageContent";
import LoaderContent from "../Load/LoaderContent";
import ErrorLoading from "../Errors/ErrorLoading";
import { useState } from "react";


const ApplicationList = () => {
    const { appData, isLoading, isError } = useApplication();
    const { t } = useTranslation();
    return (
        <PageContent
            title={t("applicationList.componentTitle")} className={"idn-page-content IdnPageContent--overflow"}>
            <section className="idn-app-content">
                {isLoading && <LoaderContent />}
                {isError
                    ? <ErrorLoading />
                    : appData &&
                    appData.map((applicationItem, i) => (
                        <Application
                            appName={applicationItem.labelName}
                            appDescription={applicationItem.descriptions}
                            appUrl={applicationItem.link}
                            appImageUrl={applicationItem.imageUrl}
                            key={`${applicationItem.labelName}-${i}`}
                        />
                    )
                    )
                }
            </section>
        </PageContent>
    );
};

export default ApplicationList;
