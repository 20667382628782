

import { Fragment, lazy } from "react";
import { Route, Switch } from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";

import Error404 from "../Errors/Error404";
import { RouteAuth } from "./Index";


const Welcome = lazy(() => import('../../pages/Welcome/Welcome'));
const SubscribeForm = lazy(() => import('../../pages/SubscribeForm/SubscribeForm'));
const Home = lazy(() => import('../../pages/Home/Home'));
const AccountCreated = lazy(() => import('../../pages/AccountCreated/AccountCreated'));
const LegalNotice = lazy(() => import('../../pages/LegalNotice/LegalNotice'));
const Profile = lazy(() => import('../../pages/ProfileUser/Profile'));
const Cookie = lazy(() => import('../../pages/LegalNotice/Cookie'));
const SettingQuick = lazy(() => import('../../pages/Setting/Setting'));
const SettingTranslate = lazy(() => import('../../pages/Setting/SettingTranslate'));
const SettingApp = lazy(() => import('../../pages/Setting/SettingApp'));
const CreateMessage = lazy(() => import('../../pages/Communication/CreateMessage'));
const Message = lazy(() => import('../../pages/Communication/Message'));
const Saved = lazy(() => import('../../pages/Communication/SavedCommunication'));
const Sent = lazy(() => import('../../pages/Communication/SentCommunication'));
const AskModification = lazy(() => import('../../pages/ProfileUser/AskModification'));
const Logout = lazy(() => import('../../pages/Logout/Logout'));
const DashboardSummary = lazy(() => import('../../pages/Setting/DashboardSummary'));
const Redirected = lazy(() => import('../../pages/Redirected/RedirectUrl'));



export const Routes = (
) => {
    const isAuthent = useIsAuthenticated();
    return (
        <Fragment>
            <Switch>

                <Route exact path="/">
                    {
                        isAuthent
                            ? (<RouteAuth auth={true}>
                                <Home />
                            </RouteAuth>)
                            : (<RouteAuth>
                                <Welcome />
                            </RouteAuth>)
                    }
                </Route>

                {/* Secure path: User must be connected */}
                <Route exact path="/applications">
                    <RouteAuth auth={true} >
                        <Home />
                    </RouteAuth>
                </Route>
                <Route exact path="/setting/quicklinks">
                    <RouteAuth auth={true} hasAdminAccess={true}>
                        <SettingQuick />
                    </RouteAuth>
                </Route>
                <Route exact path="/setting/App">
                    <RouteAuth auth={true} hasAdminAccess={true}>
                        <SettingApp />
                    </RouteAuth>
                </Route>
                <Route exact path="/setting/dashboardSummary">
                    <RouteAuth auth={true} hasAdminAccess={true}>
                        <DashboardSummary />
                    </RouteAuth>
                </Route>
                <Route exact path="/setting/Translate">
                    <RouteAuth auth={true} hasAdminAccess={true}>
                        <SettingTranslate />
                    </RouteAuth>
                </Route>
                <Route exact path="/communication/message">
                    <RouteAuth auth={true}>
                        <Message />
                    </RouteAuth>
                </Route>
                <Route exact path="/communication/message/:_MessageId">
                    <RouteAuth auth={true}>
                        <Message />
                    </RouteAuth>
                </Route>
                <Route exact path="/AskMod">
                    <RouteAuth auth={true} IsFrance={true}>
                        <AskModification />
                    </RouteAuth>
                </Route>
                <Route exact path="/communication">
                    <RouteAuth auth={true} hasSendMessageAccess={true}>
                        <CreateMessage />
                    </RouteAuth>
                </Route>
                <Route exact path="/communication/update/:_Step">
                    <RouteAuth auth={true} hasSendMessageAccess={true}>
                        <CreateMessage />
                    </RouteAuth>
                </Route>
                <Route exact path="/communication/saved">
                    <RouteAuth auth={true} hasSendMessageAccess={true}>
                        <Saved />
                    </RouteAuth>
                </Route>
                <Route exact path="/communication/sent">
                    <RouteAuth auth={true} hasSendMessageAccess={true}>
                        <Sent />
                    </RouteAuth>
                </Route>
                <Route exact path="/profile">
                    <RouteAuth auth={true}>
                        <Profile />
                    </RouteAuth>
                </Route>
                <Route exact path="/Logout">
                    <RouteAuth auth={true} >
                        <Logout />
                    </RouteAuth>
                </Route>
                <Route exact path="/redirect">
                    <RouteAuth auth={true} >
                        <Redirected />
                    </RouteAuth>
                </Route>
                {/* Not secure path: User must not be connected */}
                <Route exact path="/welcome">
                    <RouteAuth>
                        <Welcome />
                    </RouteAuth>
                </Route>
                <Route exact path="/subscribe/:EmployeeNumber">
                    <RouteAuth>
                        <SubscribeForm />
                    </RouteAuth>
                </Route>
                <Route exact path="/subscribe">
                    <RouteAuth>
                        <SubscribeForm />
                    </RouteAuth>
                </Route>
                <Route exact path="/account">
                    <RouteAuth>
                        <AccountCreated />
                    </RouteAuth>
                </Route>
                <Route exact path="/legal" >
                    <RouteAuth isMenuPath={false}>
                        <LegalNotice />
                    </RouteAuth>
                </Route>
                <Route exact path="/cookies" >
                    <RouteAuth isMenuPath={false}>
                        <Cookie />
                    </RouteAuth>
                </Route>
                <Route path="*" >
                    <RouteAuth isMenuPath={false}>
                        <Error404 />
                    </RouteAuth>
                </Route>
            </Switch>
        </Fragment>
    );
}

