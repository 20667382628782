import Moment from 'moment';
import { isValidPhoneNumber } from "react-phone-number-input";
import { MessageSummaryLabel, theming } from "./constants";
import { Language } from "./enum";
import { PasswordValidation, ThemeElior } from "./enum";

import { LocalStorageKey } from "./constants";

import eliorServiceBkg from "../assets/images/idn-eliorservices-page-bkg.png";
import eliorServiceLogo from "../assets/images/idn-eliorservices-logo.png";
import eliorServiceBkg2 from "../assets/images/idn-eliorservices-page-bkg-2.png";
import eliorServiceBkg3 from "../assets/images/idn-eliorservices-page-bkg-3.png";
import eliorServiceBkg4 from "../assets/images/idn-eliorservices-page-bkg-4.png";

import eliorDefaultBkg from "../assets/images/idn-eliordefault-page-bkg.png";
import eliorDefaultLogo from "../assets/images/idn-eliordefault-logo.png";
import eliorDefaultBkg2 from "../assets/images/idn-eliordefault-page-bkg-2.png";
import eliorDefaultBkg3 from "../assets/images/idn-eliordefault-page-bkg-3.png";
import eliorDefaultBkg4 from "../assets/images/idn-eliordefault-page-bkg-4.png";

import eliorAnsambleBkg from "../assets/images/idn-eliorAnsamble-page-bkg.png";
import eliorAnsambleLogo from "../assets/images/idn-eliorAnsamble-logo.png";
import eliorAnsambleBkg2 from "../assets/images/idn-eliorAnsamble-page-bkg-2.png";
import eliorAnsambleBkg3 from "../assets/images/idn-eliorAnsamble-page-bkg-3.png";
import eliorAnsambleBkg4 from "../assets/images/idn-eliorAnsamble-page-bkg-4.png";

import eliorNorthAmericaBkg from "../assets/images/idn-eliorNorthAmerica-page-bkg.png";
import eliorNorthAmericaLogo from "../assets/images/idn-eliorNorthAmerica-logo.png";
import eliorNorthAmericaBkg2 from "../assets/images/idn-eliorNorthAmerica-page-bkg-2.png";
import eliorNorthAmericaBkg3 from "../assets/images/idn-eliorNorthAmerica-page-bkg-3.png";
import eliorNorthAmericaBkg4 from "../assets/images/idn-eliorNorthAmerica-page-bkg-4.png";

import eliorGroupBkg from "../assets/images/idn-eliorgroup-page-bkg.png";
import eliorGroupLogo from "../assets/images/idn-eliorgroup-logo.png";
import eliorGroupBkg2 from "../assets/images/idn-eliorgroup-page-bkg-2.png";
import eliorGroupBkg3 from "../assets/images/idn-eliorgroup-page-bkg-3.png";
import eliorGroupBkg4 from "../assets/images/idn-eliorgroup-page-bkg-4.png";

const utilsServices = {
  TransformEliorDateToStandardDate(eliorDate: string) {
    let year = eliorDate.substring(0, 4);
    let month = eliorDate.substring(4, 6);
    let day = eliorDate.substring(6, 8);

    return `${day}/${month}/${year}`;
  },
  checkFrenchFormat(phonevalue: string) {
    let phoneRegex = /^(0)\s*[1-9](?:[\s.-]*\d{2}){4}$/;
    return phonevalue.match(phoneRegex);
  },

  checkEmailFormat(emailvalue: string) {
    let emailRegex = /^[^@]+@[^@]+\.[^@]+$/;

    return emailvalue.match(emailRegex);
  },

  renderPasswordValidation(enumValidation: PasswordValidation) {
    switch (enumValidation) {
      case PasswordValidation.normal:
        return "";
      case PasswordValidation.error:
        return "idn-error";
      case PasswordValidation.success:
        return "idn-success";
    }
  },
  addPlus(phone: string | undefined) {
    return (phone?.toString().startsWith("+") ? phone : `+${phone}`);
  },
  setMobilePhone(phonevalue?: string | undefined | null) {
    return phonevalue && phonevalue.length > 5 ?
      this.addPlus(phonevalue)
      : "";
  },
  setMobilePhoneFr(phonevalue?: string | undefined | null) {
    if (phonevalue && isValidPhoneNumber(this.addPlus(phonevalue))) {

      if (phonevalue?.startsWith("33") || phonevalue?.startsWith("+33")) {
        var phoneWithOutZero = utilsServices.verifLoginForFr(phonevalue);
        if (!phoneWithOutZero.startsWith("336") && !phoneWithOutZero.startsWith("337") && !phoneWithOutZero.startsWith("+336") && !phoneWithOutZero.startsWith("+337")) {
          return "";
        }
        else {
          return this.addPlus(phonevalue)
        }
      }
      else {
        return this.addPlus(phonevalue)
      }

    }
    else {
      return "";
    }

  },
  setTheme(themingValue: ThemeElior) {
    switch (themingValue) {
      case ThemeElior.None:
      case ThemeElior.Country:
        //Set Service theming
        document.documentElement.style.setProperty(
          theming.InternalName.color,
          theming.Country.color
        );
        document.documentElement.style.setProperty(
          theming.InternalName.color2,
          theming.Country.color2
        );
        document.documentElement.style.setProperty(
          theming.InternalName.gradientColor,
          theming.Country.gradientColor
        );
        document.documentElement.style.setProperty(
          theming.InternalName.pageBkgHeader,
          "url('" + eliorDefaultLogo + "')"
        );
        document.documentElement.style.setProperty(
          theming.InternalName.pageBkg,
          "url('" + eliorDefaultBkg + "')"
        );
        document.documentElement.style.setProperty(
          theming.InternalName.pageBkg2,
          "url('" + eliorDefaultBkg2 + "')"
        );
        document.documentElement.style.setProperty(
          theming.InternalName.pageBkg3,
          "url('" + eliorDefaultBkg3 + "')"
        );
        document.documentElement.style.setProperty(
          theming.InternalName.pageBkg4,
          "url('" + eliorDefaultBkg4 + "')"
        );

        break;
      case ThemeElior.Service:
        //Set default theming
        document.documentElement.style.setProperty(
          theming.InternalName.color,
          theming.Service.color
        );
        document.documentElement.style.setProperty(
          theming.InternalName.color2,
          theming.Service.color2
        );
        document.documentElement.style.setProperty(
          theming.InternalName.gradientColor,
          theming.Service.gradientColor
        );
        document.documentElement.style.setProperty(
          theming.InternalName.pageBkgHeader,
          "url('" + eliorServiceLogo + "')"
        );
        document.documentElement.style.setProperty(
          theming.InternalName.pageBkg,
          "url('" + eliorServiceBkg + "')"
        );
        document.documentElement.style.setProperty(
          theming.InternalName.pageBkg2,
          "url('" + eliorServiceBkg2 + "')"
        );
        document.documentElement.style.setProperty(
          theming.InternalName.pageBkg3,
          "url('" + eliorServiceBkg3 + "')"
        );
        document.documentElement.style.setProperty(
          theming.InternalName.pageBkg4,
          "url('" + eliorServiceBkg4 + "')"
        );
        break;
      case ThemeElior.Ansamble:
        //Set default theming
        document.documentElement.style.setProperty(
          theming.InternalName.color,
          theming.Ansamble.color
        );

        document.documentElement.style.setProperty(
          theming.InternalName.color2,
          theming.Ansamble.color2
        );
        document.documentElement.style.setProperty(
          theming.InternalName.gradientColor,
          theming.Ansamble.gradientColor
        );
        document.documentElement.style.setProperty(
          theming.InternalName.pageBkgHeader,
          "url('" + eliorAnsambleLogo + "')"
        );
        document.documentElement.style.setProperty(
          theming.InternalName.pageBkg,
          "url('" + eliorAnsambleBkg + "')"
        );
        document.documentElement.style.setProperty(
          theming.InternalName.pageBkg2,
          "url('" + eliorAnsambleBkg2 + "')"
        );
        document.documentElement.style.setProperty(
          theming.InternalName.pageBkg3,
          "url('" + eliorAnsambleBkg3 + "')"
        );
        document.documentElement.style.setProperty(
          theming.InternalName.pageBkg4,
          "url('" + eliorAnsambleBkg4 + "')"
        );
        break;
      case ThemeElior.EliorNa:
        //Set default theming
        document.documentElement.style.setProperty(
          theming.InternalName.color,
          theming.NorthAmerica.color
        );

        document.documentElement.style.setProperty(
          theming.InternalName.color2,
          theming.NorthAmerica.color2
        );
        document.documentElement.style.setProperty(
          theming.InternalName.gradientColor,
          theming.NorthAmerica.gradientColor
        );
        document.documentElement.style.setProperty(
          theming.InternalName.pageBkgHeader,
          "url('" + eliorNorthAmericaLogo + "')"
        );
        document.documentElement.style.setProperty(
          theming.InternalName.pageBkg,
          "url('" + eliorNorthAmericaBkg + "')"
        );
        document.documentElement.style.setProperty(
          theming.InternalName.pageBkg2,
          "url('" + eliorNorthAmericaBkg2 + "')"
        );
        document.documentElement.style.setProperty(
          theming.InternalName.pageBkg3,
          "url('" + eliorNorthAmericaBkg3 + "')"
        );
        document.documentElement.style.setProperty(
          theming.InternalName.pageBkg4,
          "url('" + eliorNorthAmericaBkg4 + "')"
        );
        break;
      case ThemeElior.Group:
        //Set Service theming
        document.documentElement.style.setProperty(
          theming.InternalName.color,
          theming.Group.color
        );
        document.documentElement.style.setProperty(
          theming.InternalName.color2,
          theming.Group.color2
        );
        document.documentElement.style.setProperty(
          theming.InternalName.gradientColor,
          theming.Group.gradientColor
        );
        document.documentElement.style.setProperty(
          theming.InternalName.pageBkgHeader,
          "url('" + eliorGroupLogo + "')"
        );
        document.documentElement.style.setProperty(
          theming.InternalName.pageBkg,
          "url('" + eliorGroupBkg + "')"
        );
        document.documentElement.style.setProperty(
          theming.InternalName.pageBkg2,
          "url('" + eliorGroupBkg2 + "')"
        );
        document.documentElement.style.setProperty(
          theming.InternalName.pageBkg3,
          "url('" + eliorGroupBkg3 + "')"
        );
        document.documentElement.style.setProperty(
          theming.InternalName.pageBkg4,
          "url('" + eliorGroupBkg4 + "')"
        );
        break;
    }
  },

  getTheme(categoryValue: string = "") {
    let saveUser: any = localStorage.getItem(LocalStorageKey.EliorUserKey);
    let _saveUser = saveUser ? JSON.parse(saveUser) : {};
    return _saveUser.ThemeId ? _saveUser.ThemeId : 0;
  },
  getNavigatorLanguage() {
    return this.disableLanguage(navigator.language);
  },
  getNavigatorLanguageNumber() {
    let lang: any = this.getLngWithoutOption(this.getNavigatorLanguage());
    return Language[lang.toString()];
  },
  getLngFormat(lng: string) {
    return lng.indexOf("-") > -1
      ? lng.substring(lng.indexOf("-") + 1, lng.length)
      : lng;
  },
  /**
   * Permet de reccuperer la langue du navigateur pour PhoneInput de la
   * dependence react-phone-number-input
   **/
  getPhoneLanguage(language: string): any {
    let lngPhone: string = "";
    lngPhone = this.getLngFormat(language.toLowerCase());
    return lngPhone === 'en' ? 'us' : lngPhone;
  },
  verifLoginForFr(login: string) {
    let mobilPhone = login;
    if ((login.toLocaleLowerCase().startsWith("06") || login.toLocaleLowerCase().startsWith("07"))) {
      mobilPhone = `33${login.substring(1)}`;
      if (isValidPhoneNumber(utilsServices.addPlus(mobilPhone)))
        return mobilPhone;
    }

    if ((!this.verifPhone(login))) {
      if (login.startsWith("3306") || login.startsWith("3307")) {
        mobilPhone = `33${login.substring(3)}`;
      }

      if (login.startsWith("+3306") || login.startsWith("+3307")) {
        mobilPhone = `33${login.substring(4)}`;
      }

      if (isValidPhoneNumber(utilsServices.addPlus(mobilPhone)))
        return mobilPhone;
    }
    return login.replaceAll("+", "");
  },
  verifLogin(login: string) {

    let mobilPhone = login;

    if ((login.toLocaleLowerCase().startsWith("06") || login.toLocaleLowerCase().startsWith("07")) || !this.verifPhone(login)) {
      return this.verifLoginForFr(mobilPhone);
    }

    if (!login.startsWith("+") && !login.startsWith("1")) {
      let mobilPhone = `1${login}`;
      if (isValidPhoneNumber(utilsServices.addPlus(mobilPhone)))
        return mobilPhone;
    }

    return login.replaceAll("+", "");
  },
  verifPhone(phone: string | null | undefined) {
    if (!phone)
      return true;
    return (!phone.startsWith("330") && !phone.startsWith("+330"));
  },

  inputCode(inputElements: any, e: any, index: number) {
    if (inputElements.current !== null) {
      if (e.target.value !== "") {
        (inputElements.current[index] as HTMLInputElement).className = "idn-hasvalue";
        if (index === 0) {
          if (e.target.value.length > 1) {
            e.target.value = e.currentTarget.value[e.currentTarget.value.length === 0 ? 0 : e.currentTarget.value.length - 1];
          }
          (inputElements.current[index + 1] as HTMLInputElement).focus();
          (inputElements.current[index + 1] as HTMLInputElement).select();
        }
        else if (index < inputElements.current.length - 1) {
          if (e.target.value.length > 1) {
            e.target.value = e.currentTarget.value[e.currentTarget.value.length - 1];
          }
          (inputElements.current[index + 1] as HTMLInputElement).focus();
          (inputElements.current[index + 1] as HTMLInputElement).select();

        } else {
          e.target.value = e.currentTarget.value[e.currentTarget.value.length - 1];
        }
      } else {
        (inputElements.current[index] as HTMLInputElement).focus();
        (inputElements.current[index] as HTMLInputElement).select();
        if (index > 0) {
          (inputElements.current[index - 1] as HTMLInputElement).focus();
          (inputElements.current[index - 1] as HTMLInputElement).select();
          (inputElements.current[index] as HTMLInputElement).classList.remove("idn-hasvalue")
        }
      }
    }
  },
  inputCodeMoveToBack(inputElements: any, e: any, index: number) {
    if (inputElements.current !== null) {
      (inputElements.current[index] as HTMLInputElement).focus();
      (inputElements.current[index] as HTMLInputElement).select();

      if (index > 0) {
        (inputElements.current[index - 1] as HTMLInputElement).focus();
        (inputElements.current[index - 1] as HTMLInputElement).select();
        (inputElements.current[index] as HTMLInputElement).classList.remove("idn-hasvalue")
      }
    }
  },
  activeFeature(feature: any) {
    const ACTIVE_FEATURE = process.env["REACT_APP_ACTIVE_FEATURE"];

    return ACTIVE_FEATURE === "true" ? feature : "";
  },
  getLngWithoutOption(lng: string) {
    return lng.indexOf("-") > -1 ? lng.substring(0, lng.indexOf("-")) : lng;
  },
  disableLanguage(lng: string) {
    const LANGUAGE_LIST = process.env["REACT_APP_DISABLE_LANGUAGE"] ? process.env["REACT_APP_DISABLE_LANGUAGE"] : "['']";

    let REACT_APP_DISABLE_LANGUAGE = [];
    try {
      REACT_APP_DISABLE_LANGUAGE = JSON.parse(LANGUAGE_LIST);
    } catch (e) {
      console.log(" desActiveLanguage ", e)
    }

    let language = this.getLngWithoutOption(lng);
    let lngBlock = REACT_APP_DISABLE_LANGUAGE.filter((l: any) => l.indexOf(language) > -1);

    var find = false;
    for (var lang of lngBlock) {
      if (lang.indexOf("-") > -1) {
        if (lng === lang) {
          language = "en";
          find = true;
        }

      } else {
        if (lng.indexOf(lang) > -1) {
          language = "en";
          find = true;
        }
      }
    }

    if (find)
      return "en";

    return lngBlock.length === 0 ? lng : language;
  },

  scrollTo(height: number | undefined = 0) {
    window.scrollTo({
      top: height,
      behavior: 'smooth',
    });
  },
  convertDateUtcToLocalTimeZone(date: any) {
    if (!date)
      return date;

    if (this.getNavigatorLanguage() === "en" || this.getNavigatorLanguage() === "en-US") {
      return Moment.utc(date).local().format('MM/DD/YYYY HH:mm:ss');
    } else {
      return Moment.utc(date).local().format('DD/MM/YYYY HH:mm:ss');
    }
  },
  getTimeDuration(data: any) {
    if (data.SentDate) {
      let Time = data.DurationTimeSent.minutes;
      switch (data["Duration Label"]) {
        case 2:
          Time = data.DurationTimeSent.hours;
          break;
        case 3:
          Time = data.DurationTimeSent.days;
          break;
        case 4:
          Time = data.DurationTimeSent.weeks;
          break;
        case 5:
          Time = data.DurationTimeSent.month;
          break;
        case 6:
          Time = data.DurationTimeSent.year;
          break;
        default:
          Time = data.DurationTimeSent.minutes;
          break;
      }
      return Time;
    }
    return 0;
  },
  getFormatMessages(data: any, _data?: any) {
    var detail: any = data.MessageDetails;
    const IsUrgent = data.IsUrgent;
    const IsRead = data.IsRead;
    const SentDate = this.convertDateUtcToLocalTimeZone(data.SentDate);
    const SentDateFormat = this.convertDateUtcToLocalTimeZone(data.SentDate);
    const CreatedOrModifiedDate = this.convertDateUtcToLocalTimeZone(data.CreatedOrModifiedDate);
    const Time = this.getTimeDuration(data);
    const TemplateId = data["Template Id"];
    const MessageId = data.Id;
    return {
      MessageDetails: detail,
      ..._data,
      IsUrgent,
      IsRead,
      IsSent: data.IsSent,
      StatusId: data.StatusId,
      SentDate,
      SentDateFormat,
      CountRead: data.CountRead,
      CountSent: data.CountSent,
      CreatedOrModifiedDate,
      sortDate: new Date(SentDate),
      "Template Id": TemplateId,
      Time,
      MessageId,
      DurationLabel: data["Duration Label"],
      AudiencesLabel: data.AudiencesLabel,
      Audiences: data.Audiences
    };
  },
  getDetailMessages(msg: any, data: any) {
    var message = data.MessageDetails.find((m: any) => m["Language Id"] === this.getNavigatorLanguageNumber());

    var defaultMessage = null;
    if (!message)
      defaultMessage = data.MessageDetails.find((m: any) => m.IsDefault);
    var _message: any = message ? message : (data.MessageDetails.length > 1 && defaultMessage ? defaultMessage : data.MessageDetails[0])
    const result = this.getFormatMessages(data, _message);

    return msg.includes(result) ? msg : msg.concat(result);
  },
  getDetailMessagesWithAllLng(msg: any, data: any) {
    const result = this.getFormatMessages(data);
    return msg.includes(result) ? msg : msg.concat(result);
  },
  getThemeData(themeEnum: any, excluData: any[] = []) {
    let result = []
    for (const [key, value] of Object.entries(themeEnum)) {
      if (!Number(key) && key !== "0" && value !== 0) {

        result.push({
          value: value,
          label: key
        })
      }
    }
    return result
  },
  getDateFormatFr(dateValue: Date | null | undefined) {
    if (!dateValue) return "";

    //Formatage de la date qui sera transmise a l'API
    if (dateValue != null) {
      return Moment.utc(dateValue).format('DD/MM/YYYY HH:mm:ss');
    } else {
      return "";
    }
  },
  getDateFormat(dateValue: Date | null | undefined) {
    if (!dateValue) return "";

    //Formatage de la date qui sera transmise a l'API
    if (dateValue != null) {
      return Moment.utc(dateValue).format();
    } else {
      return "";
    }
  },
  getDateUtcTimeZone(date: any) {
    if (!date)
      return date;
    return Moment.utc(date).local().format();
  },
  disabledSrollBar() {
    let _id = document.querySelector('body');
    _id?.classList.add("idn-hidden-scroll-bar");
  },
  enabledSrollBar() {
    let _id = document.querySelector('body');
    _id?.classList.remove("idn-hidden-scroll-bar");
  },
  checkHTTP(href: string | undefined) {
    if (!href) return href;
    return href.trim().startsWith("http") || href.trim().startsWith("//") ? `${href}` : `//${href}`;
  },
  primeReactScrollTo(height: number | undefined = 0) {
    document.querySelector(".p-dialog-content")?.scrollTo({
      top: height,
      behavior: 'smooth',
    });
  },
  getFormatAudience(audience: any) {
    let data: any[] = [];
    for (const [key, value] of Object.entries(audience)) {
      const _value: any = value
      if (!_value["partialChecked"]) {
        data.push(key);
      }
    }

    return data;
  },
  getAudienceModel(audienceOption: any, audienceOptionsSelect: any) {
    let audienceModel: any = [];
    audienceOption.forEach((audience: any) => {
      if (audience.ParentKey === null && audience.children === null) {
        let _audienceId = Object.keys(audienceOptionsSelect).filter((key) => { return key.toString() === audience.key.toString() });
        if (_audienceId.length > 0) {
          audienceModel.push({
            AudienceId: _audienceId[0],
            ChildList: []
          })
        }
      }
      else {
        audience.children.forEach((child: any) => {
          let children = Object.fromEntries(Object.entries(audienceOptionsSelect).filter(([key]) => key.toString().startsWith(child.key + '-')));
          let parent = Object.fromEntries(Object.entries(audienceOptionsSelect).filter(([key]) => key.toString() == child.key.toString()));
          let partialValue: any = Object.values(parent);
          if (Object.keys(children).length > 0) {
            if (partialValue.length === 0) {
              Object.keys(children).forEach((element) => {
                audienceModel.push({
                  AudienceId: element,
                  ChildList: []
                })
              })
            }
            else {
              if (partialValue[0].partialChecked) {
                Object.keys(children).forEach((element) => {
                  audienceModel.push({
                    AudienceId: element,
                    ChildList: []
                  })
                })
              }
              else {
                let _childList: any = [];
                Object.keys(children).forEach((element) => {
                  _childList.push(element);
                })
                audienceModel.push({
                  AudienceId: child.key,
                  ChildList: _childList
                })
              }

            }
          }
        });
      }
    });
    return audienceModel;
  }
  ,
  getFormatAudienceInputTreeSelect(audience: any) {
    let data: any = {};
    for (const value of audience) {
      data[value.toString()] = {
        "checked": true,
        "partialChecked": false
      }

    }

    return data;
  },
  getAuSelect(audId: any, audiences: any) {
    return audId.includes(audiences.key) ? audId : audId.concat(audiences.key)
  },
  getDashBoardMessageColor(messaStatus: Number) {
    let messageColor: string = "";

    switch (messaStatus) {
      case MessageSummaryLabel.Sent:
        messageColor = "#42A5F5";
        break;

      case MessageSummaryLabel.Read:
        messageColor = "#66BB6A";
        break;

      case MessageSummaryLabel.Unread:
        messageColor = "#FFA726";
        break;
    }

    return messageColor;
  },
  downloadJsonFile(dataJson: any, fileName: string) {
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(dataJson)
    )}`;

    const link = document.createElement("a");
    link.href = jsonString;
    link.download = `${fileName}.json`;
    link.click();
  },
  getThemeColor(themeId: number) {
    let themeColor: string = "";

    switch (themeId) {
      case ThemeElior.None:
      case ThemeElior.Country:
        themeColor = theming.Country.color;
        break;

      case ThemeElior.Service:
        themeColor = theming.Service.color;
        break;

      case ThemeElior.Ansamble:
        themeColor = theming.Ansamble.color;
        break;

      case ThemeElior.EliorNa:
        themeColor = theming.NorthAmerica.color
        break;

      case ThemeElior.Group:
        themeColor = theming.Group.color;
        break;;

    }

    return themeColor;
  },
  capitalizeFirstLetter(label: string) {
    return label.charAt(0).toLocaleUpperCase().concat(label.slice(1))
  },
  tableToJson() {
    let $table = document.querySelector("table");
    let rows: any = [];
    let header: any = [];

    $table?.querySelectorAll("thead th span").forEach((item, i) => {

      if (item.innerHTML?.length !== 0) {
        header.push(item.innerHTML)
      }
    })

    $table?.querySelectorAll("tbody tr").forEach((item, i) => {
      let row: any = {};
      item.querySelectorAll("td").forEach((item, i) => {

        if (item.textContent?.length !== 0) {
          let key = header[i];
          let value = item.innerHTML;
          row[key] = value;
        }
      })

      rows.push(row)
    })

    return rows
  }

}

export default utilsServices;

