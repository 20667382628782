export enum PasswordValidation {
    normal = 0,
    success = 1,
    error = 2
}

export enum SubscribeFormStep {
    first,
    second,
    third
}

export enum ThemeElior {
    None = 0,
    Group = 1,
    Service = 2,
    Country = 3,
    Ansamble = 4,
    EliorNa = 5
}

export enum Language {
    en = 1,
    fr = 2,
    es = 3
}

export enum LanguageTranlate {
    English = 1,
    French = 2,
    Spanish = 3
}

export enum MessageStatus {
    Draft = 1,
    Sent = 2,
    Hold = 3,
    Deleted = 4,
    SendingInProgress = 5,
    SendingFailed = 6
}
export enum CultureEnum {
    EnglishUk = 1,
    EnglishUs = 2,
    French = 3,
    Spanish = 4
}
