import React, { useState, createContext } from "react";
import { useMsal } from "@azure/msal-react";
import { EliorInternalName } from "../constants";
import APIServices from "../APIServices";
import utilsServices from "../utils";
import { UserModel } from "../../models/UserModel";
import { Store } from 'react-notifications-component';
import styled from 'styled-components';
import { useIsAuthenticated } from "@azure/msal-react";
import { InformeUserLi } from "../../styles";
import { LocalStorageKey } from "../constants";
import { subscriptions } from "../../serviceWorkerRegistration";

export const DatepickerContext = createContext<any>({} as any);
export const DatepickerProvider = ({ children }: { children: any }) => {
    const saveRegistLng = localStorage.getItem("registerLng");

    const [registerLng, setRegisterLng] = useState(saveRegistLng ? JSON.parse(saveRegistLng) : []);
    /**Used to add new languages for calendar */
    const addLanguage = (langue: string) => {
        let Tlng = registerLng;
        const lng = utilsServices.disableLanguage(langue)

        if (registerLng.find((el: any) => el.langue === lng) === undefined) {
            Tlng.push({ "langue": lng })

            setRegisterLng(Tlng)
            localStorage.removeItem("registerLng");
            localStorage.setItem("registerLng", JSON.stringify(Tlng))
        }

    }

    const getDatePickerLanguage = (language: string) => {
        let lngData: string = "";
        const saveRegistLng = localStorage.getItem("registerLng");
        const reg = saveRegistLng ? JSON.parse(saveRegistLng) : [];

        const lng = language.toLowerCase();

        lngData = lng.indexOf("-") > -1
            ? lng.substring(0, lng.indexOf("-"))
            : lng;

        if (reg.find((el: any) => el.langue === lngData) === undefined) {
            lngData = "en";
        }
        return lngData;
    }

    return (
        <DatepickerContext.Provider value={{ registerLng, addLanguage, getDatePickerLanguage }}>
            {children}
        </DatepickerContext.Provider>
    )
}

export const UserProfileContext = createContext<any>({} as any);
export const UserProfileProvider = ({ children }: { children: any }) => {
    const [displayName, setDisplayName] = useState("");
    const { instance, accounts } = useMsal();
    const isAuthent = useIsAuthenticated();

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const saveUser = localStorage.getItem(LocalStorageKey.EliorUserKey);
    const [userProfile, setUserProfile] = useState(saveUser ? JSON.parse(saveUser) : {});

    //When user begin update
    const [saveUserProfile, setSaveUserProfile] = useState<any>(saveUser ? JSON.parse(saveUser) : {});

    function GetUserAD() {
        debugger
        if (accounts.length > 0) {
            let name = accounts[0].name;
            setDisplayName(name ? name : "");

            refreshUser({
                "LOGIN": accounts[0].username,
                "O365": [
                    {
                        "UPN": accounts[0].username
                    }
                ]
            })

            APIServices.GetUserAD(accounts[0].username, isAuthent)
                .then((user: any) => {
                    if (user !== null && typeof user == "object") {
                        refreshUser(user);
                        subscriptions(accounts[0].username);
                    }
                    setIsLoadingData(true);
                })
                .catch((error) => {
                    setIsLoading(false);
                    setIsLoadingData(true);
                });
        }
    }

    const refreshUser = (userProfile: any) => {
        let userP = userProfile;
        userP.displayname = accounts[0].name;
        userP.upn = userProfile['O365'][0]['UPN'];
        userP.eliorID = userProfile[EliorInternalName.EmployeId];
        userP.language = utilsServices.getNavigatorLanguage();
        userP.theme = utilsServices.getTheme();

        setSaveUserProfile(userP);
        setUserAD(userP);

        var eliorUpn = localStorage.getItem(LocalStorageKey.EliorUpnKey);
        if (eliorUpn !== null && eliorUpn !== "undefined") {
            localStorage.setItem(LocalStorageKey.EliorUpnKey, userProfile.LOGIN);
        } else {
            localStorage.removeItem(LocalStorageKey.EliorUpnKey);
        }

        localStorage.removeItem(LocalStorageKey.EliorThemeKey);

        if (userProfile.Category)
            localStorage.setItem(
                LocalStorageKey.EliorThemeKey,
                userProfile.Category
            );
    }

    /**
     * Deconnexion de l'utilisateur
     */
    const handleLogin = () => {
        localStorage.removeItem(LocalStorageKey.EliorUpnKey);
        localStorage.removeItem(LocalStorageKey.EliorThemeKey);
        localStorage.removeItem(LocalStorageKey.EliorUserKey);
        instance.logoutRedirect({
            postLogoutRedirectUri: "/",
        });
    };

    //Sauvegarde des donnees utilisateur dans le localstorage
    const setUserAD = (user: any) => {
        if (typeof user == "object") {
            localStorage.setItem(LocalStorageKey.EliorUserKey, JSON.stringify(user));
            const getUser = localStorage.getItem(LocalStorageKey.EliorUserKey);
            setUserProfile(getUser ? JSON.parse(getUser) : userProfile);
        }
    }



    const saveDataProfile = (user: any) => {
        var userP: UserModel = userProfile;
        setSaveUserProfile(userP);

        saveUserProfile.optAcceptEmail = user.optAcceptEmail;
        saveUserProfile.optAcceptPhone = user.optAcceptPhone;

        saveUserProfile.emailpersonnel = user.emailpersonnel;
        saveUserProfile.telephonemobile = user.telephonemobile;

        saveUserProfile.mailjetSmsSenderName = user.mailjetSmsSenderName;
        saveUserProfile.mailjetSmsMessage = user.mailjetSmsMessage;
        saveUserProfile.mailjetEmailWarning = user.mailjetEmailWarning;
        saveUserProfile[EliorInternalName.PersonnalEmail] = user.emailpersonnel;
        saveUserProfile[EliorInternalName.PersonnalPhone] = user.telephonemobile;
        setSaveUserProfile(saveUserProfile);
    }


    const editSuccess = (message: any) => {
        Store.addNotification({
            title: "",
            message: message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        });
    }

    const editError = (message: any) => {
        Store.addNotification({
            title: "",
            message: message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        });
    }

    //Verifie si le information personnelles sont renseigné
    const verifPersonelInfoExist = () => {
        const tel = userProfile[EliorInternalName.PersonnalPhone] ? userProfile[EliorInternalName.PersonnalPhone].toString().trim() : null;
        const mail = userProfile[EliorInternalName.PersonnalEmail] ? userProfile[EliorInternalName.PersonnalEmail].toString().trim() : null;
        return tel || mail;
    }

    const informeUserAboutPersoInfos = (message: string) => {

        const InformeUserLi = styled.div`
            padding:2px;
            margin:0;
            text-align:center;
            font-size:12px;
            background:red;
            color:white;
            width:100%;
        `

        return (
            !verifPersonelInfoExist()
                ? <React.Fragment>
                    <InformeUserLi>
                        {message}
                    </InformeUserLi>
                </React.Fragment>
                : <div className="idn-user-menu__nav-title">
                </div>
        );
    }

    const informeUserAboutPersoInfosIcone = () => {
        return (
            !verifPersonelInfoExist()
            && <InformeUserLi>!</InformeUserLi>
        );
    }

    const userMailIsEqualUpn = (mail: string) => {
        return mail.toString().trim() === userProfile.upn.toString().trim();
    }

    return (
        <UserProfileContext.Provider value={{
            userProfile, handleLogin, setUserAD,
            isLoading,
            editSuccess, saveUserProfile, isLoadingData, editError,
            informeUserAboutPersoInfos, informeUserAboutPersoInfosIcone, saveDataProfile,
            userMailIsEqualUpn, accounts, isAuthent, displayName, GetUserAD
        }}>
            {children}
        </UserProfileContext.Provider>
    )
}

export const ErrorLoadingContext = createContext<any>({} as any);
export const ErrorLoadingProvider = ({ children }: { children: any }) => {
    const [errorList, setErrorList] = useState<any>([]);

    const addError = (refresh: any) => {
        var errs = errorList.find((err: any) => err.id === refresh.id);
        var errAll = errorList;
        if (errs) {
            errAll = errorList.filter((err: any) => err.id !== refresh.id);
            errAll.push(refresh);
        } else {
            errAll.push(refresh);
        }

    }

    const removeError = (refresh: any) => {
        var errs = errorList.find((err: any) => err.id === refresh.id);
        if (errs) {
            var errAll = errorList.filter((err: any) => err.id !== refresh.id);
            setErrorList(errAll);
        }
    }

    const updateError = (errorRefresh: any, setRefresh: any) => {
        var errs = errorList.find((err: any) => err.id === errorRefresh.id);
        if (errs) {
            if (errs.status) {
                setRefresh(true);
                errs.status = false;
                addError(errs);
            }
        }
    }

    return (
        <ErrorLoadingContext.Provider value={{
            addError, removeError, updateError, errorList
        }}>
            {children}
        </ErrorLoadingContext.Provider>
    )
}

export const AudienceContext = createContext<any>({} as any);
export const AudienceProvider = ({ children }: { children: any }) => {
    const [_audienceType, setAudienceType] = useState<any>([]);
    const [_audiences, setAudience] = useState<any>([]);
    const [_isUrgent, setIsUrgent] = useState(false);

    const SaveAudienceInfos = (audience: any, isUrgent: boolean, audienceType: any) => {
        setAudience(audience);
        setIsUrgent(isUrgent);
        if (audienceType)
            setAudienceType(audienceType);
    }

    return (
        <AudienceContext.Provider value={{
            SaveAudienceInfos, _audiences, _isUrgent, _audienceType
        }}>
            {children}
        </AudienceContext.Provider>
    )
}
/**
 * Context for messageProvider
 */
export const MessageContext = createContext<any>({} as any);
/**
 * To make persistent the information of a selected message
 * @param param0 
 * @returns 
 */
export const MessageProvider = ({ children }: { children: any }) => {
    const [message, setMessage] = useState<any>([]);
    const SaveMessageInfos = (message: any) => {
        setMessage(message);
    }

    const [unReadMessage, setUnReadMessage] = useState<any>([]);
    const SaveUnReadMessage = (message: any) => {
        setUnReadMessage(message);
    }

    return (
        <MessageContext.Provider value={{
            SaveMessageInfos, message,
            SaveUnReadMessage, unReadMessage
        }}>
            {children}
        </MessageContext.Provider>
    )
}

export const GetStatusAppContext = createContext<any>({} as any);
export const GetStatusAppProvider = ({ children }: { children: any }) => {
    const [data, setData] = useState<any>();
    const [_isLoadingSatusApp, setIsLoadingSatusApp] = useState<boolean>(true);
    const SaveDataInfos = (datas: any) => {
        setData(datas.data);
        setIsLoadingSatusApp(datas.status)
    }

    return (
        <GetStatusAppContext.Provider value={{
            SaveDataInfos, data, _isLoadingSatusApp
        }}>
            {children}
        </GetStatusAppContext.Provider>
    )
}


export const GetRightContext = createContext<any>({} as any);
export const GetRightProvider = ({ children }: { children: any }) => {
    const [right, setRight] = useState<any>();
    const [_isLoadingRight, setIsLoadingRight] = useState<boolean>(true);
    const SaveDataInfos = (datas: any) => {
        setRight(datas.data);
        setIsLoadingRight(datas.status)
    }

    return (
        <GetRightContext.Provider value={{
            SaveDataInfos, right, _isLoadingRight
        }}>
            {children}
        </GetRightContext.Provider>
    )
}

