
import { useState, useContext, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import PWABadge from "pwa-badge";
import { useIsAuthenticated } from "@azure/msal-react";

import { ApplicationModel } from "../../models/ApplicationModel";
import { QuickLinkModel } from "../../models/QuickLinkModel";
import { UserProfileContext, ErrorLoadingContext, GetStatusAppContext, GetRightContext } from "../context";
import APIServices from "../APIServices";
import utilsServices from "../utils";
import { _fetchOrUpdateReceivedMessage } from "../redux/features/receivedMessage";
import { setUnReadMessage } from "../redux/features/unReadMessage";
import { setTotalItems } from "../redux/features/totalItemsMessages";
import {
    selectDisplayMessage, selectunReadMessages,
    selectNotificationNumber, selectMessageCrud, selectTotalItemsMessages
} from "../redux/selectors";
import { setDisplayMessage } from "../redux/features/displayMessage";
import { setNotificationNumber } from "../redux/features/notificationMessageNumber";
import { selectPushNotification } from "../redux/selectors";
import { setPushNotification } from "../redux/features/pushNotification";
import { UserModel } from "../../models/UserModel";
import { setMessageId } from "../redux/features/messageCrud";


export function useUpdateTitle(setlanguage: Boolean = true) {
    const [document_title, setDocumentTitle] = useState("");
    useEffect(() => {
        var language = utilsServices.getNavigatorLanguage();
        var titlechange = "www.elior.team | " + document_title;
        if (setlanguage) {
            titlechange = titlechange + " | (" + language + ")";
        }
        document.title = titlechange.replaceAll("|", "-");
    }, [document_title]);

    return { setDocumentTitle }
}


export function useApplication() {

    const [appData, setData] = useState<ApplicationModel[]>();
    const { addError, removeError, errorList, updateError } = useContext(ErrorLoadingContext);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setError] = useState(false);
    const [isRefresh, setRefresh] = useState(false);

    const errorRefresh = {
        id: "useApplication",
        setRefresh: setRefresh,
        status: false
    }

    useEffect(() => {
        if (isError) {
            updateError(errorRefresh, setRefresh);
        } else {
            removeError(errorRefresh);
        }
    }, [errorList])

    useEffect(() => {
        setError(false);
        setIsLoading(true);

        async function GetUserApplications() {
            try {
                var applications = await APIServices.GetUserApplications();
                setData(applications);
                setError(false);
                removeError(errorRefresh);
            } catch (e) {
                console.log("APIServices.GetUserApplications " + e);
                setError(true);
                addError(errorRefresh)
            } finally {
                setRefresh(false)
                setIsLoading(false);
            }
        }
        GetUserApplications();
    }, [isRefresh]);


    return { appData, isLoading, isError }

}


export function useQuickLink() {

    const [quickLinks, setQuickLinksData] = useState<QuickLinkModel[]>();
    const [quickLinkCategory, setQuickLinkCategoryData] = useState([]);

    const { addError, removeError, errorList, updateError } = useContext(ErrorLoadingContext);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setError] = useState(false);
    const [isRefresh, setRefresh] = useState(false);

    const errorRefresh = {
        id: "useQuickLink",
        setRefresh: setRefresh,
        status: false
    }

    useEffect(() => {
        if (isError) {
            updateError(errorRefresh, setRefresh);
        } else {
            removeError(errorRefresh);
        }
    }, [errorList])

    useEffect(() => {

        setIsLoading(true);
        setError(false);


        async function GetUserQuickLinks() {
            try {
                var quickLinks = await APIServices.GetUserQuickLinks();
                setQuickLinksData(quickLinks);
                setQuickLinkCategoryData(quickLinks.reduce(
                    (cat: any, quickLink) =>
                        cat.includes(quickLink.categoryLabel) ? cat : cat.concat(quickLink.categoryLabel),
                    []
                ));
                setError(false);
                removeError(errorRefresh);
            } catch (e) {
                console.log("APIServices.GetUserQuickLinks " + e);
                setError(true);
                addError(errorRefresh)
            } finally {
                setRefresh(false)
                setIsLoading(false);
            }
        }

        GetUserQuickLinks();

    }, [isRefresh]);


    return { quickLinks, quickLinkCategory, isLoading, isError }

}

export function useQuickLinkAll(languageId: any) {

    const [quickLinks, setQuickLinksData] = useState<QuickLinkModel[]>();
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setError] = useState(false);

    async function GetAllQuickLinks() {
        setIsLoading(true);
        try {
            var quickLinks = await APIServices.GetAllQuickLink({
                language: languageId
            });
            setQuickLinksData(quickLinks);
            setError(false);
        } catch (e) {
            setError(true);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        setIsLoading(true);
        setError(false);
        GetAllQuickLinks();

    }, []);


    return { quickLinks, isLoading, isError, GetAllQuickLinks }

}


export function useGetTranslations(languageId: any) {

    const [dataTrans, setDataTrans] = useState<any[]>();
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setError] = useState(false);
    const compareData = (a: any, b: any) => {
        if (a.categoryLanguageId > b.categoryLanguageId) return 1;
        if (a.categoryLanguageId < b.categoryLanguageId) return -1;
        return 0;
    }

    async function GetDataTrans() {
        setIsLoading(true);
        try {
            var data = await APIServices.GetTranslations(languageId);
            data = data.sort(compareData)
            console.log("data")
            console.log(data)
            setDataTrans(data);
            setError(false);
        } catch (e) {
            setError(true);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        setIsLoading(true);
        setError(false);
        GetDataTrans();

    }, []);


    return { dataTrans, isLoading, isError, GetDataTrans }

}

export function useApplicationAll(languageId: any) {

    const [applications, setApplicationsData] = useState<ApplicationModel[]>();
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setError] = useState(false);

    async function GetAllApplications() {
        setIsLoading(true);
        try {
            var app = await APIServices.GetAllApplication({
                language: languageId
            });
            setApplicationsData(app);
            setError(false);
        } catch (e) {
            setError(true);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        setIsLoading(true);
        setError(false);
        GetAllApplications();
    }, []);


    return { applications, isLoading, isError, GetAllApplications }

}

export function useAudienceType() {

    const [audienceType, setAudienceTypeData] = useState<any[]>();
    const [_isLoadingAudienceType, setIsLoading] = useState(true);
    const [isError, setError] = useState(false);

    async function GetAudienceTye() {
        try {
            var app = await APIServices.GetAllAudienceType();
            setAudienceTypeData(app);
            setError(false);
        } catch (e) {
            setError(true);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        setIsLoading(true);
        setError(false);
        GetAudienceTye();
    }, []);
    return { audienceType, _isLoadingAudienceType, isError, GetAudienceTye }
}

export function useUnReadMessageReceived() {
    const { _setNotificationNumber } = useNotificationNumber();
    const unReadMessage = useSelector(selectunReadMessages);
    const totalItems = useSelector(selectTotalItemsMessages);

    const dispatch = useDispatch();
    async function fetchOrUpdateUnReadMessage(message: any, unReadMessageNumber: number) {
        dispatch(setUnReadMessage(message, unReadMessageNumber));
        dispatch(setTotalItems(unReadMessageNumber));
    }

    function _setTotalItems(unReadMessageNumber: number) {
        dispatch(setTotalItems(unReadMessageNumber));
    }

    const hiddenNotif = (messageId: any) => {
        navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/service-worker.js`);

        const options = { tag: messageId };

        navigator.serviceWorker.ready.then((registration) => {
            registration.getNotifications(options).then((notifications: any) => {
                notifications.forEach((element: any) => {
                    element.close();
                });
            })
        });
    }

    const ReadMessage = async (msg: any) => {
        if (!msg.IsRead) {
            APIServices.ReadMessage(msg.MessageId)
                .then(async () => {
                    hiddenNotif(msg.MessageId)
                    let data: any = await APIServices.GetReceivedUnreadMessage();
                    _setNotificationNumber(data.TotalItems);
                    let messages: any = data.Messages.reduce(
                        (msg: any, data: any) => {
                            return utilsServices.getDetailMessages(msg, data);
                        }
                        , []
                    );
                    fetchOrUpdateUnReadMessage(messages, data.TotalItems);
                });

        }


    }

    return {
        fetchOrUpdateUnReadMessage,
        _setTotalItems, ReadMessage, hiddenNotif,
        Messages: unReadMessage.Messages,
        TotalItems: totalItems
    }
}


export function useMessageReceived() {
    const [receivedMessages, setReceivedMessages] = useState<any>(null);
    const [receivedMessage, setReceivedMessage] = useState<any>(null);
    const [totalItems, setTotalItems] = useState(0);
    async function fetchOrUpdateReceivedMessage(params: any) {
        const data: any = await APIServices.GetReceivedMessage(params);
        let messages = data.Messages.reduce(
            (msg: any, data: any) => {
                return utilsServices.getDetailMessages(msg, data);
            }
            , []
        );
        setReceivedMessages({ Messages: messages, TotalItems: data.TotalItems });
    }

    useEffect(() => {
        if (receivedMessages && receivedMessages !== null && receivedMessages.Messages) {
            var _data: any = [...receivedMessages.Messages];
            setReceivedMessage(_data);
            setTotalItems(receivedMessages.TotalItems)
        }
    }, [receivedMessages])

    return {
        fetchOrUpdateReceivedMessage,
        receivedMessage,
        totalItems
    }
}


export function useMessage() {
    const [messageSaved, setMessageReceived] = useState<any>();
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setError] = useState(false);

    async function GetMessage(params: any = {}) {
        return new Promise(async (resolve, reject) => {
            try {
                var app = await APIServices.GetMessage(params);
                setMessageReceived(app);
                setError(false);
                resolve(app)
            } catch (e) {
                setError(true);
                reject(e)
            } finally {
                setIsLoading(false);
            }
        })
    }

    return { messageSaved, isLoading, isError, GetMessage }
}

export function useMessageCrud() {

    const messageId = useSelector(selectMessageCrud);
    const dispatch = useDispatch();

    function _setMessageId(messageId: any) {
        dispatch(setMessageId(messageId));
    }

    return { messageId, _setMessageId }
}

export function useDisplayMessage() {
    const displayMessage = useSelector(selectDisplayMessage);
    const dispatch = useDispatch();

    async function _setMessage(message: any) {
        if (message) {
            dispatch(setDisplayMessage(message));

        } else {
            dispatch(setDisplayMessage({
                MessageDetails: [],
                'Template Id': 0,
                Title: ""
            }));
        }
    }

    return { displayMessage, _setMessage }
}

export function usePushNotification() {
    const pushNotification = useSelector(selectPushNotification);
    const dispatch = useDispatch();

    function _setSubscibe(hubCon: any) {
        dispatch(setPushNotification(hubCon));
    }

    return { pushNotification, _setSubscibe }

}

export function useNotificationNumber() {
    const notificationNumber = useSelector(selectNotificationNumber);
    const dispatch = useDispatch();
    const badge = new PWABadge();
    const [_compte, setCompte] = useState(0);

    function setBadge(compte: any) {
        if ("setAppBadge" in navigator && "clearAppBadge" in navigator) {
            let newVariable: any;
            newVariable = window.navigator;
            newVariable.setAppBadge(compte)
                .then(() => {
                })
                .catch((error: any) => {
                });

        }
    }

    function _setNotificationNumber(nbr: number) {
        setBadge(nbr);
        dispatch(setNotificationNumber(nbr));
    }

    function _resetNotificationNumber() {
        setBadge(0);
        dispatch(setNotificationNumber(0));
    }

    return {
        _setNotificationNumber, _resetNotificationNumber,
        notificationNumber
    }
}

export const useGetRight = () => {
    const [right, setRight] = useState<any>();
    const [_isLoadingRight, setIsLoadingRight] = useState<boolean>(true);
    const isAuthent = useIsAuthenticated();
    const { SaveDataInfos } = useContext(GetRightContext);

    useEffect(() => {
        if (isAuthent) {
            APIServices.GetUserRights()
                .then((data) => {
                    setRight(data);
                    if (data) {
                        SaveDataInfos({
                            data: data,
                            status: false
                        })
                        setIsLoadingRight(false);
                    }

                })
                .catch(() => {
                    SaveDataInfos({
                        data: {},
                        status: false
                    })
                    setRight({});
                    setIsLoadingRight(false);
                });
        } else {
            setIsLoadingRight(false);
        }
    }, [])

    return {
        right,
        _isLoadingRight
    }
}

export const useGetStatusApp = () => {
    const { SaveDataInfos } = useContext(GetStatusAppContext);
    const [data, setData] = useState<any>();
    const [_isLoadingSatusApp, setIsLoadingSatusApp] = useState<boolean>(true);

    useEffect(() => {

        APIServices.GetStatusApp()
            .then((data: any) => {
                let _data = {
                    displayMaintenancePage: true
                }
                if (typeof data === "object") {
                    _data = data;
                }

                setData(_data);
                SaveDataInfos({
                    data: _data,
                    status: false
                })
                setIsLoadingSatusApp(false);
            })
            .catch(() => {
                setData({
                    displayMaintenancePage: true
                });
                SaveDataInfos({
                    data: {
                        displayMaintenancePage: true
                    },
                    status: false
                })
                setIsLoadingSatusApp(false);

            });
    }, [])

    return {
        data,
        _isLoadingSatusApp
    }
}

export const useUserProfile = () => {
    const { userProfile,
        setUserAD, editSuccess, saveUserProfile,
        saveDataProfile
    } = useContext(UserProfileContext);

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const saveUser = localStorage.getItem("user");


    //Pour la modification des informations du user
    const updateUserAD = (user: any): Promise<any> => {
        return new Promise((resolve, reject) => {
            setIsLoading(true);
            saveDataProfile(user);
            APIServices.InitializeModifyUserAccount(saveUserProfile)
                .then((result: any) => {
                    setIsLoading(false);
                    if (result.status === 200) {
                        result.json().then((resultValue: any) => {
                            resolve(resultValue);
                        });
                    } else {
                        resolve(result.status)
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    reject(error);
                });

        })

    }

    const validateUpdateUserADCode = (user: any): Promise<any> => {
        return new Promise((resolve, reject) => {
            setIsLoading(true);
            let userP: UserModel = saveUserProfile;
            userP.verificationCode = user.verificationCode;
            APIServices.UpdateUserAD(userP)
                .then((result: any) => {
                    setIsLoading(false);
                    if (result.status === 200) {
                        result.json().then((resultValue: any) => {
                            resolve(resultValue);
                        });
                    } else {
                        if (result.status === 401) {
                            resolve(result.status)
                        } else {
                            result.json().then((error: any) => {
                                resolve(error.message)
                            });
                        }
                    }

                })
                .catch((error) => {
                    setIsLoading(false);
                    resolve(error);
                });
        })
    }

    return {
        updateUserAD,
        validateUpdateUserADCode,
        isLoading,
        isLoadingData, setIsLoading
    }
}


export function useWindowSize() {
    const isClient = typeof window === "object";

    function getSize() {
        return {
            width: isClient ? window.innerWidth : undefined,
            height: isClient ? window.innerHeight : undefined
        };
    }

    const [windowSize, setWindowSize] = useState(getSize);

    useEffect(() => {
        function handleResize() {
            setWindowSize(getSize());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return { windowSize };
}


export function useSendMessageButtonStatus() {
    const sendBtnStatus = process.env["REACT_APP_ACTIVE_SEND_MESSAGE_BUTTON"];
    const sendTestBtnStatus = process.env["REACT_APP_ACTIVE_SEND_MESSAGE_TEST_BUTTON"];

    return { sendBtnStatus, sendTestBtnStatus }
}