
import { Helmet } from 'react-helmet';

export default function NoIndex() {
    return (
        <Helmet>
            <link rel="canonical" href={window.location.href} />
            <meta name="robots" content="noindex, noFollow, NoArchive, NoSnippet, NoImageIndex" />
        </Helmet>
    )
}