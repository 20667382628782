import { Fragment } from "react";
import { Route } from "react-router-dom";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { useIsAuthenticated } from "@azure/msal-react";

import Error404 from "../Errors/Error404";
import Home from "../../pages/Home/Home";
import MaintenancePage from "../Errors/MaintenancePage";
import { useGetRight, useGetStatusApp } from "../../services/hooks";
import Loader from "../Load/Loader";
import utilsServices from "../../services/utils";
import Authentification from "../Authentification";

export const RouteAuth = (
    {
        children,
        auth = false,
        hasAdminAccess = false,
        hasSendMessageAccess = false,
        IsFrance = false,
        IsUsa = false,
        isMenuPath = true
    }: {
        children: any,
        auth?: boolean,
        hasAdminAccess?: boolean,
        hasSendMessageAccess?: boolean,
        IsFrance?: boolean,
        IsUsa?: boolean,
        isMenuPath?: boolean
    }
) => {
    const isAuthent = useIsAuthenticated();
    const { right, _isLoadingRight } = useGetRight();
    const { data, _isLoadingSatusApp } = useGetStatusApp();

    if (!_isLoadingSatusApp && data.displayMaintenancePage) {
        utilsServices.setTheme(1);
        return (
            <Fragment>
                <MaintenancePage date={data.activDate} />
            </Fragment>
        );
    }

    if (!_isLoadingSatusApp && !_isLoadingRight) {
        if (auth) {
            if (!isAuthent) {
                return (<Authentification>
                    {children}
                </Authentification>)
            }

            if (hasAdminAccess) {
                if (!right || !right.HasAdminAccess) {
                    return (
                        <Fragment>
                            {
                                _isLoadingRight && _isLoadingSatusApp ? <Loader />
                                    : <Error404 />
                            }
                        </Fragment>
                    );
                }
            }

            if (hasSendMessageAccess) {
                if (!right || !right.HasSendMessageAccess) {
                    return (
                        <Fragment>
                            {
                                _isLoadingRight && _isLoadingSatusApp ? <Loader />
                                    : <Error404 />
                            }
                        </Fragment>
                    );
                }
            }


        }
        else {
            if (isAuthent && isMenuPath) {
                return (
                    <Fragment>
                        {
                            _isLoadingRight && _isLoadingSatusApp ? <Loader />
                                : <Route exact>
                                    <Home />
                                </Route>
                        }

                    </Fragment>
                );
            }
            else {
                <Fragment>
                    {
                        _isLoadingRight && _isLoadingSatusApp ? <Loader />
                            : <Route exact>
                                {children}
                            </Route>
                    }
                </Fragment>
            }
        }

        if (IsFrance) {
            if (!right || !right.IsFrance) {
                return (
                    <Fragment>
                        {
                            _isLoadingRight ? <Loader />
                                : <Error404 />
                        }
                    </Fragment>
                );
            }
        }

        if (IsUsa) {
            if (!right || !right.IsUsa) {
                return (
                    <Fragment>
                        {
                            _isLoadingRight ? <Loader />
                                : <Error404 />
                        }
                    </Fragment>
                );
            }
        }
        if (hasSendMessageAccess) {
            if (!right || !right.HasSendMessageAccess) {
                return (
                    <Fragment>
                        {
                            _isLoadingRight ? <Loader />
                                : <Error404 />
                        }
                    </Fragment>
                );
            }
        }


    }
    else {
        if (isAuthent) {
            return (
                <Fragment>
                    {
                        _isLoadingRight ? <Loader />
                            : <Route exact>
                                <Home />
                            </Route>
                    }

                </Fragment>
            );
        }
    }


    return (
        <Fragment>
            {
                _isLoadingRight && _isLoadingSatusApp ? <Loader />
                    : <Route exact>
                        {children}
                    </Route>
            }
        </Fragment>
    );
}
