import { ApplicationModel } from "../models/ApplicationModel";
import { QuickLinkModel } from "../models/QuickLinkModel";
import { UserModel } from "../models/UserModel";
import { Config } from "./constants";
import utilsServices from "./utils";
import { PublicIpService } from "./PublicIpService";
import { SendMailToHr } from "../models/SendMailToHr";
import { FetchApi } from "./FetchApi";
import { Language } from "./enum";
import { EditTranslationModel } from "../models/EditTranslationModel";
import { useIsAuthenticated } from "@azure/msal-react";

const publicIp = new PublicIpService();
const fetchApi = new FetchApi();

const APIServices = {
  SetEliorApiAllowRetryCheckDOB: (EmployeeId: string, dateOfBirth: string): Promise<boolean> => {
    return new Promise(async (resolve, reject) => {

      const data = await publicIp.getInfos();
      let urlApi = Config.EliorAPI.PostUpdateRetryCheckDOB + `?Id=${EmployeeId}&dateOfBirth=${dateOfBirth}&ip=${data.ip}`;

      fetchApi.post(urlApi)
        .then((response: any) => {
          if (response.status === 200) {
            resolve(true);
          }
          else {
            resolve(false);
          }
        }).catch((error) => {
          reject(error);
        });
    });
  },

  GetEliorApiAllowRetryCheckDOB: (EmployeeId: string): Promise<any> => {
    return new Promise(async (resolve, reject) => {

      const data = await publicIp.getInfos();
      let urlApi = Config.EliorAPI.GetRetrieveRetryCheckDOB + `?Id=${EmployeeId}&ip=${data.ip}`;

      fetchApi.getMethod(urlApi)
        .then((response: any) => {
          if (response.status === 200) {
            response.json().then((data: any) => {
              resolve(data);
            })
              .catch((error: any) => {
                reject(error);
              });
          }
          else {
            reject(response);
          }
        }).catch((error) => {
          reject(error);
        });
    });
  },

  GetMdmUserById: (identifiantValue: string) => {
    return new Promise((resolve, reject) => {

      let urlApi = Config.EliorAPI.GetUserById + "?Id=" + identifiantValue;

      fetchApi.getMethod(urlApi)
        .then((response: any) => {
          if (response.status === 200 || response.status === 404) {
            response.json().then((data: any) => {
              resolve(data);
            })
              .catch((error: any) => {
                reject(error);
              });
          }
          else {
            reject(response);
          }
        }).catch((error) => {
          reject(error);
        });
    });
  },

  CheckExistAADuser: (identifiantValue: string): Promise<boolean> => {
    return new Promise((resolve, reject) => {

      let urlApi = Config.EliorAPI.CheckIfAADUserAlreadyExist + "?Id=" + identifiantValue;
      fetchApi.getMethod(urlApi)
        .then((response: any) => {
          if (response.status === 204 || response.status === 200) {
            if (response.status === 204)
              resolve(false);
            else {
              resolve(true);
            }
          } else {
            reject(response);
          }

        }).catch((error) => {
          reject(error);
        });
    });
  },

  InitializeUserAccount: (userModel: UserModel): Promise<any> => {
    return new Promise((resolve, reject) => {
      let urlApi = Config.EliorAPI.InitializeUserAccount;
      fetchApi.post(urlApi, userModel)
        .then(response => {
          resolve(response);
        }).catch((error) => {
          reject(error);
        });
    })
  },

  CreateAADaccountAsync: (userModel: UserModel) => {
    return new Promise(async (resolve, reject) => {
      const data = await publicIp.getInfos();
      let urlApi = Config.EliorAPI.CreateAADaccountAsync + `?ip=${data.ip}`;
      fetchApi.post(urlApi, userModel)
        .then(response => {
          resolve(response);
        }).catch((error) => {
          reject(error);
        });
    })
  },

  GetUserApplications: (): Promise<ApplicationModel[]> => {
    return new Promise((resolve, reject) => {

      let urlApi = Config.EliorAPI.GetUserApplications + `?ThemeId=${utilsServices.getTheme()}&languageId=${utilsServices.getNavigatorLanguageNumber()}`;

      fetchApi.getMethod(urlApi)
        .then(async (data: any) => {
          var _data = await data.json();
          resolve(_data);
        })
        .catch((error) => {
          reject(error);
        });

    });
  },

  GetUserAD: (userValue: string, isLogged: boolean): Promise<any> => {
    return new Promise((resolve, reject) => {
      let urlApi = Config.EliorAPI.GetUserAD + "?value=" + userValue + "&language=" + utilsServices.getNavigatorLanguage().toLocaleLowerCase() + "&isLogged=" + isLogged;
      fetchApi.getMethod(urlApi)
        .then((response: any) => {
          if (response.status === 204) {
            resolve("");
          } else {
            response.json().then((data: any) => {
              resolve(data);
            })
              .catch((error: any) => {
                reject(error);
              });
          }

        }).catch((error) => {
          reject(error);
        });
    })
  },

  UpdateUserAD: (userModel: UserModel): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      const data = await publicIp.getInfos();
      let urlApi = Config.EliorAPI.UpdateUserAD + `?ip=${data.ip}`;

      fetchApi.patch(urlApi, userModel)
        .then((response: any) => {
          resolve(response);
        }).catch((error) => {
          reject(error);
        });

    })
  },
  InitializeModifyUserAccount: (userModel: UserModel): Promise<any> => {
    return new Promise((resolve, reject) => {
      let urlApi = Config.EliorAPI.InitializeModifyUserAccount;
      fetchApi.post(urlApi, userModel)
        .then(response => {
          resolve(response);
        }).catch((error) => {
          reject(error);
        });
    })
  },
  GetUserQuickLinks: (): Promise<QuickLinkModel[]> => {
    return new Promise((resolve, reject) => {

      let urlApi = Config.EliorAPI.GetUserQuickLinks + `?ThemeId=${utilsServices.getTheme()}&languageId=${utilsServices.getNavigatorLanguageNumber()}`;

      fetchApi.getMethodWithUpn({
        url: urlApi
      })
        .then(async (data: any) => {
          var _data = await data.json();
          resolve(_data);
        })
        .catch((error) => {
          reject(error);
        });

    });
  },
  GetAllQuickLink: (params: any): Promise<QuickLinkModel[]> => {
    return new Promise((resolve, reject) => {

      let urlApi = Config.EliorAPI.GetAllQuickLink

      fetchApi.getMethod(urlApi, params)
        .then(async (data: any) => {
          var _data = await data.json();
          resolve(_data);
        })
        .catch((error) => {
          reject(error);
        });

    });
  },
  GetAllApplication: (params: any): Promise<QuickLinkModel[]> => {
    return new Promise((resolve, reject) => {

      let urlApi = Config.EliorAPI.GetAllApplication

      fetchApi.getMethod(urlApi, params)
        .then(async (data: any) => {
          var _data = await data.json();
          resolve(_data);
        })
        .catch((error) => {
          reject(error);
        });

    });
  },
  GetAllCategories: (languageId: any): Promise<any[]> => {
    return new Promise((resolve, reject) => {

      let urlApi = Config.EliorAPI.GetAllCategories + `?languageId=${languageId}`;

      fetchApi.getMethod(urlApi)
        .then(async (data: any) => {
          var _data = await data.json();
          resolve(_data);
        })
        .catch((error) => {
          reject(error);
        });

    });
  },
  CreateUpdateMultipleQuicklink: (quickLinkModel: any[]): Promise<any[]> => {
    return new Promise((resolve, reject) => {
      let urlApi = Config.EliorAPI.CreateUpdateMultipleQuicklink;


      fetchApi.postWithUpn({
        url: urlApi, body: quickLinkModel
      })
        .then(async (response: any) => {
          resolve(await response.json());
        }).catch((error) => {
          reject(error);
        });

    });
  },
  createUpdateQuickLink: (quickLinkModel: any[]): Promise<any[]> => {
    return new Promise((resolve, reject) => {
      let urlApi = Config.EliorAPI.CreateUpdateQuickLink;

      fetchApi.putWithUpn({
        url: urlApi, body: quickLinkModel
      })
        .then(async (response: any) => {

          if (response.status == 200)
            resolve(await response.json());
          else
            reject(response);

        }).catch((error) => {
          reject(error);
        });

    });
  },
  createUpdateApplication: (applicationModel: any[]): Promise<any[]> => {
    return new Promise((resolve, reject) => {
      let urlApi = Config.EliorAPI.CreateUpdateApplication;

      fetchApi.putWithUpn({
        url: urlApi, body: applicationModel
      })
        .then(async (response: any) => {

          if (response.status == 200)
            resolve(await response.json());
          else
            reject(response);

        }).catch((error) => {
          reject(error);
        });

    });
  },
  GetAllAudience: (audienctypeId: any, ids: any = ""): Promise<any[]> => {
    return new Promise((resolve, reject) => {
      let urlApi = `${Config.EliorAPI.GetAllAudience}?audiencetypeid=${audienctypeId}&ids=${ids}`;

      fetchApi.getMethodWithUpn({
        url: urlApi
      })
        .then(async (data: any) => {
          var _data = await data.json();
          resolve(_data);
        })
        .catch((error) => {
          reject(error);
        });

    });
  },
  getAudienceCount: (params: any): Promise<any[]> => {
    return new Promise((resolve, reject) => {
      let urlApi = Config.EliorAPI.GetAudienceCount;
      fetchApi.getMethodWithUpn({
        url: urlApi,
        body: params
      })
        .then(async (data: any) => {
          var _data = await data.json();
          resolve(_data);
        })
        .catch((error) => {
          reject(error);
        });

    });
  },
  GetAllAudienceType: (): Promise<any[]> => {
    return new Promise((resolve, reject) => {
      let urlApi = Config.EliorAPI.GetAllAudienceType;
      fetchApi.getMethodWithUpn({
        url: urlApi
      })
        .then(async (data: any) => {
          var _data = await data.json();
          resolve(_data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  CreateOrUpdateMessages: (message: any): Promise<any[]> => {
    return new Promise((resolve, reject) => {
      let urlApi = Config.EliorAPI.CreateOrUpdateMessages;

      fetchApi.postWithUpn({
        url: urlApi, body: message
      })
        .then((response: any) => {
          resolve(response);
        }).catch((error) => {
          reject(error);
        });

    });
  },
  SendMessage: (messageId: number): Promise<any[]> => {
    return new Promise((resolve, reject) => {
      let urlApi = `${Config.EliorAPI.SendMessage}?id=${messageId}`;
      fetchApi.postWithUpn({
        url: urlApi
      })
        .then((response: any) => {
          resolve(response);
        }).catch((error) => {
          reject(error);
        });

    });
  },
  ReadMessage: (messageId: number): Promise<any[]> => {
    return new Promise((resolve, reject) => {
      let urlApi = `${Config.EliorAPI.ReadMessage}?id=${messageId}`;

      fetchApi.postWithUpn({
        url: urlApi
      })
        .then((response: any) => {
          resolve(response);
        }).catch((error) => {
          reject(error);
        });

    });
  },
  GetReceivedUnreadMessage: (params: any = null): Promise<any[]> => {
    return new Promise((resolve, reject) => {
      let urlApi = Config.EliorAPI.MessagesSentEmp;
      fetchApi.getMethodWithUpn({
        url: urlApi,
        body: params == null ? {
          IsRead: false, Language: utilsServices.getNavigatorLanguageNumber()
        } : { ...params, Language: utilsServices.getNavigatorLanguageNumber() }
      })
        .then((response: any) => {
          if (response.status === 200) {
            response.json()
              .then((data: any) => {
                resolve(data)
              })
          }

        }).catch((error) => {
          reject(error);
        });

    });
  },
  GetReceivedMessage: (params: any): Promise<any[]> => {
    return new Promise((resolve, reject) => {
      let urlApi = Config.EliorAPI.MessagesSentEmp;
      fetchApi.getMethodWithUpn({
        url: urlApi,
        body: { ...params, Language: utilsServices.getNavigatorLanguageNumber() }
      })
        .then((response: any) => {
          if (response.status === 200) {
            response.json()
              .then((data: any) => {
                resolve(data)
              })
          }
        }).catch((error) => {
          reject(error);
        });
    });
  },
  GetMessage: (params: any = {}): Promise<any[]> => {
    return new Promise((resolve, reject) => {
      let urlApi = Config.EliorAPI.GetMessages;
      fetchApi.getMethod(urlApi, { ...params })
        .then((response: any) => {
          if (response.status === 200) {
            response.json()
              .then((data: any) => {
                resolve(data)
              })
          }

        }).catch((error) => {
          reject(error);
        });
    });
  },
  DeleteMessages: (id: any): Promise<any[]> => {
    return new Promise((resolve, reject) => {
      let urlApi = `${Config.EliorAPI.DeleteMessages}?id=${id}`;
      fetchApi.delete(urlApi)
        .then((response: any) => {
          resolve(response);
        }).catch((error) => {
          reject(error);
        });
    });
  },
  GetUserRights: (): Promise<any[]> => {
    return new Promise((resolve, reject) => {
      let urlApi = Config.EliorAPI.GetUserRights;
      fetchApi.getMethodWithUpn({
        url: urlApi
      })
        .then((response: any) => {
          response.json()
            .then((data: any) => {
              resolve(data)
            })
        }).catch((error) => {
          reject(error);
        });
    });
  },
  CreateUpdateCategory: (cat: any): Promise<any[]> => {
    return new Promise((resolve, reject) => {
      let urlApi = Config.EliorAPI.AddUpdateCategory;

      fetchApi.putWithUpn({
        url: urlApi, body: cat
      })
        .then(async (response: any) => {
          resolve(await response.json());
        }).catch((error) => {
          reject(error);
        });

    });
  },
  GetTheme: (): Promise<any[]> => {
    return new Promise((resolve, reject) => {

      let urlApi = Config.EliorAPI.GetThemes;

      fetchApi.getMethod(urlApi)
        .then(async (response: any) => {
          resolve(await response.json());
        }).catch((error) => {
          reject(error);
        });

    });
  },
  GetTranslations: (languageId: number): Promise<any[]> => {
    return new Promise((resolve, reject) => {
      let urlApi = `${Config.EliorAPI.GetTranslations}?CultureId=${languageId}`;
      fetchApi.getMethod(urlApi)
        .then(async (response: any) => {
          resolve(await response.json());
        }).catch((error) => {
          reject(error);
        });

    });
  },
  GetDashboardSummary: (languageId: string, params: any = {}): Promise<any[]> => {
    return new Promise((resolve, reject) => {
      let urlApi = Config.EliorAPI.GetDashboardSummary + `?language=${languageId}`;
      fetchApi.getMethod(urlApi, { ...params })
        .then((response: any) => {
          if (response.status === 200) {
            response.json()
              .then((data: any) => {
                resolve(data)
              })
          }

        }).catch((error) => {
          reject(error);
        });
    })
  },
  PostSendDetailsToHr: (sendMailToHr: SendMailToHr): Promise<boolean> => {
    return new Promise(async (resolve, reject) => {
      let urlApi = Config.EliorAPI.PostSendDetailsToHr;
      var _fetchApi = new FetchApi();
      _fetchApi.post(urlApi, sendMailToHr)
        .then((data: any) => {
          resolve(data);
        })
        .catch((err) => {
          console.log(" PostSendDetailsToHr ", err)
          reject(err);
        })
    })
  },
  GetStatusApp: (): Promise<any[]> => {
    return new Promise((resolve, reject) => {

      let urlApi = Config.EliorAPI.GetStatusApp;

      fetchApi.get(urlApi)
        .then(async (response: any) => {
          resolve(await response.json());
        }).catch((error) => {
          reject(error);
        });
    })
  },
  UpdateTranslations: (transJson: any[]): Promise<any[]> => {
    return new Promise((resolve, reject) => {

      let urlApi = `${Config.EliorAPI.UpdateTranslations}`;
      let transJsonMap = transJson.map((json: any) => new EditTranslationModel(json));
      fetchApi.postWithUpn({
        url: urlApi, body: transJsonMap
      })
        .then(async (response: any) => {
          resolve(await response.json());
        }).catch((error) => {
          reject(error);
        });

    });
  },
  JsonTranslations: (languageId: number): Promise<any[]> => {
    return new Promise((resolve, reject) => {
      let urlApi = `${Config.EliorAPI.JsonTranslations}?CultureId=${languageId}`;
      fetchApi.getMethod(urlApi)
        .then(async (response: any) => {
          resolve(response);
        }).catch((error) => {
          reject(error);
        });

    });
  },
  FetchGoogleAnalyticData: (postData: any): Promise<any[]> => {
    return new Promise((resolve, reject) => {
      let urlApi = Config.EliorAPI.GoogleAnalyticData;
      var _fetchApi = new FetchApi();

      _fetchApi.post(urlApi, postData)
        .then(async (response: any) => {
          if (response.status === 200) {
            response.json()
              .then((data: any) => {
                resolve(data)
              });
          }
        })
        .catch((err) => {
          console.log("Fetch GoogleAnalytic data ", err)
          reject(err);
        })

    });
  },
  GetAudienceUserList: (messageId: number): Promise<any[]> => {
    return new Promise((resolve, reject) => {
      let urlApi = `${Config.EliorAPI.GetAudienceUserList}?id=${messageId}`;
      fetchApi.getMethodWithUpn({
        url: urlApi
      })
        .then((response: any) => {
          if (response.status === 200) {
            response.json()
              .then((data: any) => {
                resolve(data)
              });
          }
        }).catch((error) => {
          reject(error);
        });

    });
  },
}

export default APIServices;