// This optional code is used to register a service worker.
// register() is not called by default.

import { BASE_URL } from "./services/constants";
import { FetchApi } from "./services/FetchApi";
import * as rdd from 'react-device-detect';

const _fetchApi = new FetchApi()
// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://cra.link/PWA

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
  // [::1] is the IPv6 localhost address.
  window.location.hostname === '[::1]' ||
  // 127.0.0.0/8 are considered localhost for IPv4.
  window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

type Config = {
  onSuccess?: (registration: ServiceWorkerRegistration) => void;
  onUpdate?: (registration: ServiceWorkerRegistration) => void;
};

export function register(config?: Config) {
  const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
  registerValidSW(swUrl, config);
}

let pushServiceWorkerRegistration: any;

function urlBase64ToUint8Array(base64String: any) {
  var padding = '='.repeat((4 - base64String.length % 4) % 4);
  var base64 = (base64String + padding)
    .replace(/\-/g, '+')
    .replace(/_/g, '/');

  var rawData = window.atob(base64);
  var outputArray = new Uint8Array(rawData.length);

  for (var i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

function uint8ArrayToArray(uint8Array: any) {
  var array = [];

  for (var i = 0; i < uint8Array.byteLength; i++) {
    array[i] = uint8Array[i];
  }

  return array;
}


function addLog(_upn: any, error: string) {
  fetch(`${BASE_URL}/subscriptions`, {
    method: 'POST',
    headers: _fetchApi._headers,
    body: JSON.stringify({
      "Upn": _upn,
      "Device": [
        rdd.osName,
        rdd.osVersion,
        rdd.browserName,
        rdd.browserVersion
      ],
      "Error": error
    })
  }).then(async (response) => {
    console.log('Successfully log erreur subscribed');
  }).catch(function (error) {
    console.log('Failed to store the Push Notifications subscription on server: ', error);
  });

}

export function unsubscribeFromPushNotifications() {
  pushServiceWorkerRegistration.pushManager.getSubscription().then(function (pushSubscription: any) {
    if (pushSubscription) {
      pushSubscription.unsubscribe().then(function () {
        fetch(`${BASE_URL}/DiscardSubscription?endpoint=${encodeURIComponent(pushSubscription.endpoint)}`,
          {
            method: 'DELETE',
            headers: _fetchApi._headers
          }
        ).then(function (response) {
          if (response.ok) {
            console.log('Successfully unsubscribed from Push Notifications');
          } else {
            console.log('Failed to discard the Push Notifications subscription from server');
          }
        }).catch(function (error) {
          console.log('Failed to discard the Push Notifications subscription from server: ' + error);
        });

      }).catch(function (error: any) {
        console.log('Failed to unsubscribe from Push Notifications: ' + error);
      });
    }
  });
}
export function subscriptions(_upn: any) {
  if ('serviceWorker' in navigator) {
    console.log('supported'); // this pops up in safari
    let applicationServerPublicKey = urlBase64ToUint8Array(process.env["REACT_APP_ELIOR_CLIENT_SUBSCRIBE_ID"]);


    navigator.serviceWorker.ready
      .then((serviceWorkerRegistration: any) => {

        if (('PushManager' in window)) {
          console.log('Web Push supported')
          serviceWorkerRegistration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: applicationServerPublicKey
          }).then(function (pushSubscription: any) {

            const rawKey = pushSubscription.getKey ? pushSubscription.getKey('p256dh') : '';
            const key = rawKey ? btoa(String.fromCharCode.apply(null, uint8ArrayToArray(new Uint8Array(rawKey)))) : '';
            const rawAuthSecret = pushSubscription.getKey ? pushSubscription.getKey('auth') : '';
            const authSecret = rawAuthSecret ? btoa(String.fromCharCode.apply(null, uint8ArrayToArray(new Uint8Array(rawAuthSecret)))) : '';
            const endpoint = pushSubscription.endpoint;

            fetch(`${BASE_URL}/subscriptions`, {
              method: 'POST',
              headers: _fetchApi._headers,
              body: JSON.stringify({
                "EndpointKey": endpoint,
                "PrivateKey": key,
                "Auth": authSecret,
                "Upn": _upn,
                "Device": [
                  rdd.osName,
                  rdd.osVersion,
                  rdd.browserName,
                  rdd.browserVersion
                ]
              })
            }).then(async (response) => {
              console.log('Successfully subscribed for Push Notifications');
            }).catch(function (error) {
              console.log('Failed to store the Push Notifications subscription on server: ', error);
              subscriptions(_upn);
            });
          }).catch(function (error: any) {
            addLog(_upn, error.message);
            if (Notification.permission !== "denied") {
              Notification.requestPermission().then((permission) => {
                if (permission === "granted") {
                  try {
                    console.log('Failed to subscription pushManager: ', error);

                    try {
                      unsubscribeFromPushNotifications();
                    } catch (e) {

                    }
                    subscriptions(_upn);
                  } catch (e) {
                    console.log(" error  ", e);
                  }
                }
              });
            }
          });
          // Web Push supported.
        } else {
          addLog(_upn, 'Web Push not supported');
          console.log('Web Push not supported');
          // Web Push not supported.
        }

      })
  } else {
    // I get this on iphone version of chrome
    addLog(_upn, 'Service workers not supported');
    console.log('Service workers not supported');
  }



}

function registerValidSW(swUrl: string, config?: Config) {

  navigator.serviceWorker
    .register(swUrl)
    .then((registration: any) => {
      pushServiceWorkerRegistration = registration;

      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker == null) {
          return;
        }
        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              // At this point, the updated precached content has been fetched,
              // but the previous service worker will still serve the older
              // content until all client tabs are closed.
              console.log(
                'New content is available and will be used when all ' +
                'tabs for this page are closed. See https://cra.link/PWA.'
              );

              // Execute callback
              if (config && config.onUpdate) {
                config.onUpdate(registration);
              }
            } else {
              // At this point, everything has been precached.
              // It's the perfect time to display a
              // "Content is cached for offline use." message.
              console.log('Content is cached for offline use.');

              // Execute callback
              if (config && config.onSuccess) {
                config.onSuccess(registration);
              }
            }
          }
        };
      };
    })
    .catch((error) => {
      console.error('Error during service worker registration:', error);
    });
}

function checkValidServiceWorker(swUrl: string, config?: Config) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl, {
    headers: { 'Service-Worker': 'script' },
  })
    .then((response) => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get('content-type');
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      console.log('No internet connection found. App is running in offline mode.');
    });
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
}
