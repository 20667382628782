import Authentification from "../../components/Authentification";
import ApplicationList from "../../components/Application/ApplicationList";
import QuickLinkList from "../../components/QuickLink/QuickLinkList";
import styled from "styled-components";
import NewMessage from "../../components/Message/NewMessage";
import NoIndex from "../../components/Meta/NoIndex";
import { useUpdateTitle } from "../../services/hooks";
import { useEffect } from "react";
import InfoMessage from "../../components/Message/InfoMessage";


const Home = () => {

  const { setDocumentTitle } = useUpdateTitle();

  useEffect(() => {
    setDocumentTitle("HomePage | Welcome");
  })



  return (

    <Authentification>
      <NoIndex />
      <div className="home">
        <section className="idn-section">
          <section className="idn-app-content--width">
            <NewMessage />
          </section>
          <section className="PageContent--flex IdnPageContent--minheight homeContent">
            <section className="idn-app-content--width idn-app-content--mobile-minheight">
              <ApplicationList />
            </section>
            <section className="idn-link-content">
              <InfoMessage />
              <QuickLinkList />
            </section>
          </section>
        </section>
      </div>

    </Authentification>
  );
};

export default Home;
