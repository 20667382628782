import { useTranslation } from "react-i18next";
import GlobalLoad from "./GlobalLoad";

const Loader = () => {
  const { t } = useTranslation();

  return (
    <div id="idn-app-loader" className="idn-loader">
      <GlobalLoad title={t("loader.Loading")} />
    </div>
  );
};

export default Loader;
